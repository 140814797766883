// export default HeaderComponent;
import React, {useState, useEffect} from 'react';
import { Layout, Button } from 'antd';
// 导入公司logo图片
import kungfuLogo from './KFD-logo-white.png';
const { Header } = Layout;


const headerStyle = {
  background: '#f6a69e', // 设置淡红色背景
  color: 'black',       // 设置黑色文字颜色
  paddingLeft: '24px',  // 左边内边距，使标题靠左
  display: 'flex',      // 使用 Flex 布局
  alignItems: 'center', // 垂直居中标题
  justifyContent: 'space-between'
};

const logoStyle = {
  marginRight: '12px',
  height: '100%', // 让图片的高度与父容器一样高
};



function HeaderComponent({username, handleLogout}) {
  const [companyName, setCompanyName] = useState("请登录");
  useEffect(() => {
    if (username) {
      setCompanyName(
        username.includes('beijing') ? '功夫电子商务（北京）有限公司' :
        username.includes('shanghai') ? '功夫电子商务（上海）有限公司' :
        '功夫电子商务发票主管'
      );
    } else {
      setCompanyName("请登录");
    }
  }, [username]);

  return (
    <Header className="header" style={headerStyle}>
      {/* 插入公司logo图片 */}
      <img src={kungfuLogo} alt="公司logo" style={logoStyle} />
      <h1 style={{ color: 'white', margin: 0 }}>{companyName}</h1>
      {username && (
        <Button type="link" onClick={handleLogout}>
          退出账号
        </Button>
      )}
    </Header>
  );
}

export default HeaderComponent;

