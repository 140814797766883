// LoginPage.js
import React, { useState } from 'react';
import { Input, Button } from 'antd';

const LoginPage = ({ handleLogin, error }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  console.log(username);



  const handleLoginClick = () => {
    handleLogin(username, password);
  };


  const handleKeyDown = (e) => {
    // Check if the pressed key is Enter
    if (e.key === 'Enter') {
      if (username && password) { // 检查 username 和 password 是否存在且不为空
        handleLogin(username, password);
      } else {
        console.log('Username and/or password is empty');
      }
    }
  };
  


  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'left' }}>
      <h2 style={{ marginBottom: '20px' }}>请登录</h2>
      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
        <label htmlFor="username" style={{ marginRight: '10px', width: '80px' }}>用户名: </label>
        <Input
          id="username"
          style={{ width: '200px' }}
          placeholder="请输入用户名"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
        <label htmlFor="password" style={{ marginRight: '10px', width: '80px' }}>密码: </label>
        <Input.Password
          id="password"
          style={{ width: '200px' }}
          placeholder="请输入密码"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
      <Button type="primary" onClick={handleLoginClick}>
        登录
      </Button>
      {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
      </div>
    </div>
    
  );
};

export default LoginPage;
