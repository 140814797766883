import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import sha256 from 'js-sha256'; // 引入js-sha256库
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';

import Header from './Header';
import Sidebar from './Sidebar';
import InvoiceVerification from './InvoiceVerification';
import SalesInvoice from './SalesInvoice';
import PurchaseInvoice from './PurchaseInvoice';
import OtherInvoice from './OtherInvoice';
import LoginPage from './LoginPage';

// console.log(users);
const { Content } = Layout;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');


  useEffect(() => {
    // Check local storage for login information
    const storedLoginInfo = localStorage.getItem('loginInfo');

    if (storedLoginInfo) {
      const { loggedIn, loginTime, username } = JSON.parse(storedLoginInfo);

      // Check if logged in within the last two hours
      const twoHoursInMillis = 2 * 60 * 60 * 1000;
      if (loggedIn && new Date().getTime() - loginTime < twoHoursInMillis) {
        setLoggedIn(true);
        setUsername(username);
      }
    }
  }, []);

  useEffect(() => {
    const updateUsername = () => {
      // Check local storage for updated username
      const storedLoginInfo = localStorage.getItem('loginInfo');
  
      if (storedLoginInfo) {
        const { username } = JSON.parse(storedLoginInfo);
        setUsername(username);
      } else {
        setUsername(''); // 如果没有存储 username，则将其设置为空
      }
    };
  
    updateUsername(); // Update immediately when component mounts
  
    // Subscribe to changes in username
    window.addEventListener('storage', updateUsername);
  
    return () => {
      // Unsubscribe when component unmounts
      window.removeEventListener('storage', updateUsername);
    };
  }, [setUsername]);
  



  const handleLogin = async (username, password) => {
    if (username.trim() === '' || password.trim() === '') {
      setError('用户名或密码不能为空');
      return;
    }
    try {
      // const response = await fetch('http://localhost:9000/login', {
      const response = await fetch('https://kfdtech.cn/api/login', {
      // const response = await fetch('https://kfdtech.cn/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        // 登录成功
        const data = await response.json();
        setUsername(username);
        setLoggedIn(true);
        const loginInfo = {
          username,
          loggedIn: true,
          loginTime: new Date().getTime(),
        };
        localStorage.setItem('loginInfo', JSON.stringify(loginInfo));
      } else {
        const errorData = await response.json();
        setError(errorData.error);
        // setError('用户名或密码错误');
        setLoggedIn(false);
      }
    } catch (error) {
      console.log('登录失败：', error);
      setError('登录失败，请稍后重试');
      setLoggedIn(false);
    }
    };



  const handleLogout = () => {
    // Clear local storage
    localStorage.removeItem('loginInfo');
    setUsername('');
    // Set loggedIn state to false
    setLoggedIn(false);
  };

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
      <Header username={username} handleLogout={handleLogout} />
        <Layout>
          {/* Render Sidebar only if logged in */}
          {loggedIn && <Sidebar username={username} />}
          <Layout>
            <Content style={{ background: 'white' }}>
              {loggedIn ? (
                <Routes>
                  {/* <Route
                    path="/invoice-verification"
                    element={<InvoiceVerification />}
                  /> */}
                  {/* Route for invoice-verification */}
                  {loggedIn && username === 'KungfuMaster' ? (
                    <Route path="/invoice-verification" element={<Navigate to="/invoice-pool/sales" />} />
                  ) : (
                    <Route path="/invoice-verification" element={<InvoiceVerification username={username} />} />
                  )}
                  <Route
                    path="/invoice-pool/sales"
                    element={<SalesInvoice username={username} />}
                  />
                  <Route
                    path="/invoice-pool/purchase"
                    element={<PurchaseInvoice username={username} />}
                  />
                  <Route
                    path="/invoice-pool/others"
                    element={<OtherInvoice username={username} />}
                  />
                  <Route path="*" element={<Navigate to="/invoice-pool/sales" />} />
                </Routes>
              ) : (
                <LoginPage handleLogin={handleLogin} error={error}/>
              )}
            </Content>
          </Layout>
        </Layout>
        <div style={{ background: 'white', color: 'black !important', textAlign: 'center', padding: '10px' }}>
          <a href='https://beian.miit.gov.cn'  target="_blank" rel="noopener noreferrer">备案号：京ICP备2024045455号-1</a>
        </div>
      </Layout>
    </Router>
  );
}

export default App;
