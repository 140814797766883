import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { SnippetsOutlined, BookOutlined} from '@ant-design/icons';
import './App.css'; // 引入全局样式文件


const { Sider } = Layout;

function Sidebar({username}) {
    const [invoicePoolVisible, setInvoicePoolVisible] = useState(true);

    const toggleInvoicePool = () => {
      setInvoicePoolVisible(!invoicePoolVisible);
    };
    
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(['1']);

    // 在组件加载时检查用户名，并根据需要设置默认选中的菜单项
    useEffect(() => {
      if (username === 'KungfuMaster') {
          setDefaultSelectedKeys(['3.1']); // 如果是 "KungfuMaster"，则默认选中企业票池
      } else {
          setDefaultSelectedKeys(['1']); // 否则默认选中发票采集查验
      }
  }, [username]);

  return (
    <Sider width={200} theme="light">
      <Menu mode="vertical" defaultSelectedKeys={defaultSelectedKeys} className="custom-menu">
        {username !== 'KungfuMaster' && (<Menu.Item key="1" icon={<SnippetsOutlined />}   className="custom-menu-item">
          <Link to="/invoice-verification">发票采集查验</Link>
        </Menu.Item>)}
        <Menu.Item key="2" icon={<BookOutlined />} onClick={toggleInvoicePool}  className="custom-menu-item">
          企业票池
        </Menu.Item>
        {invoicePoolVisible && (
          <>
            <Menu.Item key="3.1" style={{ verticalAlign: 'top' }} className="custom-menu-item">
              <Link to="/invoice-pool/sales">销项发票</Link>
            </Menu.Item>
            <Menu.Item key="3.2"  style={{ verticalAlign: 'top' }} className="custom-menu-item">
              <Link to="/invoice-pool/purchase">进项发票</Link>
            </Menu.Item>
            <Menu.Item key="3.3"  style={{ verticalAlign: 'top' }} className="custom-menu-item">
              <Link to="/invoice-pool/others">其他发票</Link>
            </Menu.Item>
          </>
        )}
      </Menu>
    </Sider>
  );
}

export default Sidebar;
