import React, { useState } from 'react';
import { Layout, Row, Col, Input, DatePicker, Button, message, Radio } from 'antd';
import { message as antdMessage } from 'antd';
import cloudFolder from './cloud_folder.png';
import moment from 'moment';
const { Content } = Layout;



function displayErrorMessage(errorMessage, setErrorState) {
  antdMessage.error(`发票验证失败: ${errorMessage}`);
  setErrorState(null);
}

function InvoiceVerification({ username }) {
  const [invoice_number, setNumber] = useState('');
  const [invoice_date, setDate] = useState('');
  const [invoice_code, setCode] = useState('');
  const [invoice_amount, setAmount] = useState('');
  const [invoice_check_code, setCheckCode] = useState('');
  const [seller_tax_code, setSellerTaxCode] = useState('');
  const [resetFlag, setResetFlag] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadOption, setUploadOption] = useState('file');
  const [secondApiResponse, setSecondApiResponse] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [showBackupButton, setShowBackupButton] = useState(false);
  let [fileUploadResponse, setFileUploadResponse] = useState(null);

  const handleReset = () => {
    setNumber('');
    setDate('');
    setCode('');
    setAmount('');
    setCheckCode('');
    setSellerTaxCode('');
    setResetFlag(true);
    setSelectedFile(null);
    setUploadOption('file');
    setSecondApiResponse(null);
    // 清空已选文件
  if (selectedFile) {
    const fileInput = document.querySelector("input[type='file']");
    fileInput.value = '';
  }
  };

    // Handle invoice date change
    const handleInvoiceDateChange = (date, dateString) => {
  
      // You can use dateString directly or convert it to a moment object if needed
      const selectedDate = moment(dateString);
  
      // Format the date as needed (e.g., 'YYYY-MM-DD')
      const formattedDate = selectedDate.format('YYYY-MM-DD');
  
      // Update the endDate state
      setDate(formattedDate);
  
      // You can perform additional actions here if needed
    };

  const handleBackupToCloud = async (fileUploadResponse) => {
    if (fileUploadResponse) {
      try {
        // const response = await fetch('https://kfdtech.cn/add_to_db', {
        const response = await fetch('https://kfdtech.cn/api/add_to_db', {
          // const response = await fetch('http://localhost:9000/add_to_db', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'username': username
          },
          body: JSON.stringify(fileUploadResponse),
        });
  
        const responseData = await response.json(); // 解析响应的 JSON 内容
        console.log("备份的数据如下：");
        console.log(responseData);
        if (response.status === 200) {
          antdMessage.success(responseData.message);
        } else {
          antdMessage.error('发票未成功备份到云端');
        }
      } catch (error) {
        console.error('备份发票到云端时出现错误：', error);
        antdMessage.error('发票备份出错，请联系技术支持');
      }
    }
  };

  const handleVerify = async () => {
    setShowBackupButton(false); 
    setIsChecked(false);
    setSecondApiResponse(null);

    if (uploadOption === 'file') {
      if (!selectedFile) {
        message.error('请先选择上传的文件');
        return;
      }

      try {
        // const formData = new FormData(document.forms.namedItem('invoice-form'));
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('username', username); // 将用户名添加到表单数据中
        console.log('当前用户是：', username);

          // const response = await fetch('https://kfdtech.cn/upload_file', {
          const response = await fetch('https://kfdtech.cn/api/upload_file', {
            // const response = await fetch('http://localhost:9000/upload_file', {
          method: 'POST',
          headers: {
            'username': username
          },
          body: formData,
        });

        if (response.status === 200) {
          antdMessage.success('文件上传成功');
          const fileUploadResponse = await response.json();
            const remote_filePath = fileUploadResponse['filePath'];
            setFileUploadResponse(fileUploadResponse);
            const invoice_info = fileUploadResponse['ocrData'];
            const all_data = fileUploadResponse['all_data'];


            try {
                // const input_info_response = await fetch('https://kfdtech.cn/input_info_api', {
                const input_info_response = await fetch('https://kfdtech.cn/api/input_info_api', {
                  // const input_info_response = await fetch('http://localhost:9000/input_info_api', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'username': username
                },
                body:  JSON.stringify({invoices: invoice_info, // 将 invoice_info 列表包装在一个对象中，以确保整个列表作为一个属性传递
                        all_data: all_data, // all_data里封装了pdf发票里所有需要插入到数据库的数据
                        type: 'file',
                        invoiceFilePath: remote_filePath
                      }),
              });

              if (input_info_response.status === 200) {
                setIsChecked(true);
                antdMessage.success('找到该发票的真实信息，请查看。');
                setSecondApiResponse(await input_info_response.json());
                setShowBackupButton(true);
              } else {
                setIsChecked(false);
                const errorResponse = await input_info_response.json();
                displayErrorMessage(errorResponse.error, setSecondApiResponse);
                setSecondApiResponse(null);
                setShowBackupButton(false);
              }
                  } catch (error) {
                    antdMessage.error('发票无法验证，请联系技术部');
                    setSecondApiResponse(null);
                  }
          
        } else if (response.status === 400)  { 
          antdMessage.error('该文件有非税发票或通用机打发票，不提供核验，只能去税务局官网查验。');
          setSecondApiResponse(null);
        } else if (response.status === 403) {
          antdMessage.error('不支持这种类型得发票核验');
          setSecondApiResponse(null);
        } else {
          antdMessage.error('文件上传失败');
          setSecondApiResponse(null);
        }
      } catch (error) {
        console.error(error);
        antdMessage.error('上传接口有误');
        setSecondApiResponse(null);
      }
    } else if (uploadOption === 'input') {
      if (!invoice_number || !invoice_date) {
        message.error('请填写所有字段');
        return;
      }

      try {
        const dateObject = new Date(invoice_date);
        const formattedDate = dateObject.toISOString().split('T')[0];
        // const input_info_response = await fetch('https://kfdtech.cn/input_info_api', {
        const input_info_response = await fetch('https://kfdtech.cn/api/input_info_api', {
          // const input_info_response = await fetch('http://localhost:9000/input_info_api', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'username': username
          },
          body: JSON.stringify({
            InvoiceNo: invoice_number,
            InvoiceDate: formattedDate,
            Amount: invoice_amount,
            InvoiceCode: invoice_code,
            CheckCode: invoice_check_code,
            SellerTaxCode: seller_tax_code
          }),
          type: 'input'
        });

        if (input_info_response.status === 200) {
          setIsChecked(true);
          antdMessage.success('找到该发票，发票的真实信息如下所示。');
          setSecondApiResponse(await input_info_response.json());
        } else {
          setIsChecked(false);
          const errorResponse = await input_info_response.json();
          displayErrorMessage(errorResponse.error, setSecondApiResponse);
          setSecondApiResponse(null);
        }
      } catch (error) {
        antdMessage.error('发票无法验证，请联系技术部');
        setSecondApiResponse(null);
      }
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };


  const displayInvoiceData = (input_info_response) => {
    if (!input_info_response || !input_info_response.successfulResponses) {
      return null;
    }
    return (
      <div>
        {input_info_response.successfulResponses.map((item, index) => (
          <div key={index}>
            <h2>发票信息：</h2>
            <p>发票号码: {item.Invoice.Number}</p>
            <p>发票代码: {item.Invoice.Code}</p>
            <p>开票日期: {item.Invoice.Date}</p>
            <p>校验码: {item.Invoice.CheckCode}</p>
            <p>购买方名称: {item.Invoice.BuyerName}</p>
            <p>购买方统一社会信用代码/纳税人识别号: {item.Invoice.BuyerTaxCode}</p>
            <p>销售方名称: {item.Invoice.SellerName}</p>
            <p>销售方统一社会信用代码/纳税人识别号: {item.Invoice.SellerTaxCode}</p>
            <p>金额: {item.Invoice.AmountWithoutTax}</p>
            <p>税额: {item.Invoice.TaxAmount}</p>
            <p>价税合计: {item.Invoice.AmountWithTax}</p>
            <p>备注: {item.Invoice.Remark}</p>
          </div>
        ))}
      </div>
    );

  };

  return (
    <Content style={{ padding: '24px' }}>
      <div style={{ fontSize: '24px', marginBottom: '16px', fontWeight: 'bold' }}>
        发票采集查验
      </div>
      <Row gutter={[16, 16]}>
        <Col span={1}></Col>
        <Col span={5}  style={{ position: 'relative' }}>
          <img src={cloudFolder} alt="文件夹图标" />
        </Col>
        <Col span={18}>
          <Row gutter={[16, 16]}>
            <Col span={3}>
              <span className="required  red-asterisk">*</span> 发票号码:
            </Col>
            <Col span={4}>
              <Input
                placeholder={resetFlag ? '' : '请输入'}
                value={invoice_number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={3}>
              <span className="required  red-asterisk">*</span> 开票日期:
            </Col>
            <Col span={6}>
              <DatePicker
                placeholder={resetFlag ? '' : '请选择日期'}
                onChange={handleInvoiceDateChange}
                // value={invoice_date}
                // onChange={(value) => setDate(value)
                showTime={false}
              />
            </Col>
            <Col span={10}></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={3}>
              金额
            </Col>
            <Col span={4}>
              <Input
                placeholder={resetFlag ? '' : '请输入'}
                value={invoice_amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Col>
            <Col span={10}><span style={{ color: 'red' }}>(非全电发票填税前金额，全电发票填价税合计)</span></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={3}>
              发票代码:
            </Col>
            <Col span={4}>
              <Input
                placeholder={resetFlag ? '' : '请输入'}
                value={invoice_code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Col>
            <Col span={3}><span style={{ color: 'red' }}>(非全电发票必填)</span></Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={3}>
              校验码:
            </Col>
            <Col span={4}>
              <Input
                placeholder={resetFlag ? '' : '请输入'}
                value={invoice_check_code}
                onChange={(e) => setCheckCode(e.target.value)}
              />
            </Col>
            <Col span={3}><span style={{ color: 'red' }}>(非全电发票必填)</span></Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={3}>
              销售方税号:
            </Col>
            <Col span={4}>
              <Input
                placeholder={resetFlag ? '' : '请输入'}
                value={seller_tax_code}
                onChange={(e) => setSellerTaxCode(e.target.value)}
              />
            </Col>
            <Col span={3}><span style={{ color: 'red' }}>(区块链发票必填)</span></Col>
          </Row>

          <div style={{ height: '1rem' }}></div>
          <Row gutter={[16, 16]}>
            <Col span={2}>
              或
            </Col>
          </Row>
          <div style={{ height: '1rem' }}></div>
          <form name="invoice-form" encType="multipart/form-data">
            <Row gutter={[16, 16]}>
              <Col span={1.5}>
                <span className="required  red-asterisk">*</span> 选择文件:
              </Col>
              <Col span={4} style={{ width: '100%' }}>
                <input type="file" name="file" onChange={handleFileChange} />
              </Col>
            </Row>
          </form>
          <div style={{ height: '1rem' }}></div>
          <Row gutter={[16, 16]}>
            <Col span={1.5}>
              <Radio.Group onChange={(e) => setUploadOption(e.target.value)} value={uploadOption}>
                <Radio value="input">输入信息</Radio>
                <Radio value="file">上传文件</Radio>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={6}></Col>
        <Col span={18}>
          <Button type="primary" onClick={handleVerify}>
            立即查验
          </Button>
          <Button onClick={handleReset} style={{ marginLeft: '16px' }}>
            重置
          </Button>
          {showBackupButton && (
            <Button onClick={() => handleBackupToCloud(fileUploadResponse)} style={{ marginLeft: '16px' }}>
              备份发票到云端
            </Button>
          )}
        </Col>
      </Row>

      {isChecked && secondApiResponse && displayInvoiceData(secondApiResponse)}
    </Content>
  );
}

export default InvoiceVerification;
