import React, { useState } from 'react';
import { Layout, Row, Col, Input, DatePicker, Button, Table, Checkbox, Modal, Form } from 'antd';
import { message as antdMessage } from 'antd';
import moment from 'moment';

const { Content } = Layout;

function PurchaseInvoicePage({ username }) {
  const [InvoiceNumber, setInvoiceNumber] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [companyName, setcompanyName] = useState('');
  const [Amount, setAmount] = useState('');
  const [Tax, setTax] = useState('');
  const [TotalPriceWithTax, setTotalPriceWithTax] = useState('');
  const [InvoiceType, setInvoiceType] = useState('');
  // const [Invoice_file_name, setInvoice_file_name] = useState('');
  const [data, setData] = useState([]); // 存储查询结果数据
  const [resetFlag, setResetFlag] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]); // 存储选中的行
  const [emailModalVisible, setEmailModalVisible] = useState(false); // 邮箱输入框的可见状态
  const [emailForm] = Form.useForm(); // 邮箱输入表单



  const handleStartDateChange = (date, dateString) => {
    console.log('选择的开始日期：', dateString);

    // You can use dateString directly or convert it to a moment object if needed
    const selectedDate = moment(dateString);

    // Format the date as needed (e.g., 'YYYY-MM-DD')
    const formattedDate = selectedDate.format('YYYY-MM-DD');

    // Update the startDate state
    setStartDate(formattedDate);

    // You can perform additional actions here if needed
  };

  // Handle end date change
  const handleEndDateChange = (date, dateString) => {
    console.log('选择的结束日期：', dateString);

    // You can use dateString directly or convert it to a moment object if needed
    const selectedDate = moment(dateString);

    // Format the date as needed (e.g., 'YYYY-MM-DD')
    const formattedDate = selectedDate.format('YYYY-MM-DD');

    // Update the endDate state
    setEndDate(formattedDate);

    // You can perform additional actions here if needed
  };

  
     // 处理单独行选择
     const handleRowSelect = (record) => {
      const updatedData = data.map((item) =>
        item.invoice_number === record.invoice_number
          ? { ...item, select: !item.select }
          : item
      );
      setData(updatedData);
      setSelectedRows(updatedData.filter((item) => item.select));
    };
  
  
  
    // 弹出邮箱输入框
    const showEmailModal = () => {
      setEmailModalVisible(true);
    };
  
    // 处理邮箱发送
    const handleEmailSend = async (values) => {
      try {
        // const response = await fetch('https://kfdtech.cn/send_email', {
        const response = await fetch('https://kfdtech.cn/api/send_email', {
          // const response = await fetch('http://localhost:9000/send_email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'username': username
          },
          body: JSON.stringify({
            selectedRows,
            email: values.email,
          }),
        });
    
      if (response.ok) {
        const result = await response.json();
        console.log('发送成功', result);
        antdMessage.success('邮箱发送成功');
        // Hide the email modal after successful send
        setEmailModalVisible(false);
      } else {
        console.error('发送失败', response.statusText);
        antdMessage.error(response.statusText); // 这里添加错误信息的处理
      }
    } catch (error) {
      console.error('发送失败', error.message);
      antdMessage.error('邮件发送失败'); // 这里添加错误信息的处理
    }
    };


  
     // 邮箱输入框的配置
     const emailModalConfig = {
      title: '输入邮箱地址',
      visible: emailModalVisible,
      onCancel: () => setEmailModalVisible(false),
      onOk: () => {
        emailForm
          .validateFields()
          .then((values) => {
            emailForm.resetFields();
            handleEmailSend(values);
          })
          .catch((errorInfo) => {
            console.log('Validation Failed:', errorInfo);
          });
      },
    };

  
   // 查询函数
   const handleQuery = async () => {
    try {
      // const response = await fetch('https://kfdtech.cn/purchase_search_list', {
      const response = await fetch('https://kfdtech.cn/api/purchase_search_list', {
        // const response = await fetch('http://localhost:9000/purchase_search_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'username': username 
        },
        body: JSON.stringify({
          InvoiceNumber,
          startDate,
          endDate,
          companyName,
          Amount,
          Tax,
          TotalPriceWithTax,
          InvoiceType,
          // Invoice_file_name
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.length === 0) {
          antdMessage.info('该条件下查询不到任何发票信息');
        }
        setData(result);
      } else {
        console.error('查询发票时出现错误：', response.statusText);
      }
    } catch (error) {
      console.error('查询发票时出现错误：', error);
    }
  };

  // 重置函数，清空所有输入框和日期选择器
  const handleReset = () => {
    setInvoiceNumber('');
    setStartDate(null);
    setEndDate(null);
    setcompanyName('');
    setAmount('');
    setTax('');
    setTotalPriceWithTax('');
    setResetFlag(true);
    setInvoiceType('');
    // setInvoice_file_name('');
  };


  const columns = [
    // 添加一个新的列用于复选框
    {
      title: '选择',
      dataIndex: 'select',
      render: (text, record) => (
        <Checkbox checked={record.select} onChange={() => handleRowSelect(record)} />
      ),
    },
    {
      title: '发票号码',
      dataIndex: 'invoice_number',
    },
    {
      title: '开票时间',
      dataIndex: 'invoice_date',
    },
    {
      title: '销售方名称',
      dataIndex: 'seller_name',
    },
    {
      title: '金额',
      dataIndex: 'amount',
    },
    {
      title: '税额',
      dataIndex: 'tax',
    },
    {
      title: '价税合计',
      dataIndex: 'total_price_with_tax',
    },
    {
      title: '发票类型',
      dataIndex: 'invoice_type_CN',
    },
    // {
    //   title: '发票文件名',
    //   dataIndex: 'file_origin_name'
    // }
  ];

  return (
    <Content style={{ padding: '24px' }}>
      <div style={{ fontSize: '14px', marginBottom: '16px' }}>
        企业票池 &gt; 进项发票
      </div>
      <div style={{ fontSize: '24px', marginBottom: '16px', fontWeight: 'bold' }}>
        进项发票
      </div>
      {/* 空白行 */}
      <div style={{ height: '20px' }}></div>
      <Row gutter={[16, 16]} style={{ background: '#f0f0f0', alignItems: 'center'  }}>
  <Col span={1.5} style={{ paddingLeft: 8, paddingRight: 8 }}>
    <div>开票日期</div>
  </Col>
  <Col span={2.5}>
  <DatePicker
        placeholder="请选择开始日期"
        onChange={handleStartDateChange}
        style={{ width: '100%' }}
        showTime={false} // 设置 showTime 为 false，只选择日期
      />
</Col>

  <Col span={0.1}>
    <div style={{ paddingLeft: 0, paddingRight: 0 }}>-</div>
  </Col>
<Col span={2.5}>
   <DatePicker
          placeholder="请选择结束日期"
          onChange={handleEndDateChange}
          // value={endDate}
          style={{ width: '100%' }}
        showTime={false} // 设置 showTime 为 false，只选择日期
        />
</Col>

  <Col span={1.5} style={{ paddingLeft: 8, paddingRight: 8 }}>
    <div>发票号码</div>
  </Col>
  <Col span={2}>
    <Input
      placeholder={resetFlag ? '' : '请输入'}
      value={InvoiceNumber}
      onChange={(e) => setInvoiceNumber(e.target.value)}
    />
  </Col>


  <Col span={1.5} style={{ paddingLeft: 8, paddingRight: 8 }}>
  <div style={{ minWidth: '50%', width: 'max-content' }}>销售方名称</div>
  </Col>
  <Col span={2}>
    <Input
      placeholder={resetFlag ? '' : '请输入'}
      value={companyName}
      onChange={(e) => setcompanyName(e.target.value)}
    />
  </Col>
        
  <Col span={1.5} style={{paddingLeft: 8, paddingRight: 8  }}>
    <div>金额</div>
  </Col>
  <Col span={2}>
    <Input
      placeholder={resetFlag ? '' : '请输入'}
      value={Amount}
      onChange={(e) => setAmount(e.target.value)}
    />
  </Col>

  <Col span={1.5} style={{paddingLeft: 8, paddingRight: 8  }}>
    <div>税额</div>
  </Col>
  <Col span={2}>
    <Input
      placeholder={resetFlag ? '' : '请输入'}
      value={Tax}
      onChange={(e) => setTax(e.target.value)}
    />
  </Col>

  <Col span={1.5} style={{paddingLeft: 8, paddingRight: 8  }}>
    <div>价税合计</div>
  </Col>
  <Col span={2}>
    <Input
      placeholder={resetFlag ? '' : '请输入'}
      value={TotalPriceWithTax}
      onChange={(e) => setTotalPriceWithTax(e.target.value)}
    />
  </Col>
  
  <Col span={1.5} style={{paddingLeft: 8, paddingRight: 8  }}>
    <div>发票类型</div>
  </Col>
  <Col span={2}>
    <Input
      placeholder={resetFlag ? '' : '请输入'}
      value={InvoiceType}
      onChange={(e) => setInvoiceType(e.target.value)}
    />
  </Col>


  {/* <Col span={1.5} style={{paddingLeft: 8, paddingRight: 8  }}>
    <div>发票文件名</div>
  </Col>
  <Col span={2}>
    <Input
      placeholder={resetFlag ? '' : '请输入'}
      value={Invoice_file_name}
      onChange={(e) => setInvoice_file_name(e.target.value)}
    />
  </Col> */}


  <Col span={4}>
    <Button type="primary" onClick={handleQuery}>
      查询
    </Button>
    <Button onClick={handleReset} style={{ marginLeft: 16 }}>
      重置
    </Button>

    

  </Col>
</Row>

<Row>
  <Col span={20} style={{ color: 'red' }}>
    如果您想查找包含“增值税”和“专用”两个关键词的发票类型，请使用“增值税；专用”输入。请注意，两个关键词之间使用中文分号进行分隔。
    </Col>
  </Row>

      {/* 空白行 */}
      <div style={{ height: '20px' }}></div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.invoice_number}
          />
        </Col>
      </Row>


    
       {/* 发送邮箱至按钮 */}
       <Row gutter={[16, 16]}>
        <Col span={24}>
          <Button type="primary" onClick={showEmailModal} disabled={selectedRows.length === 0}>
          发送至邮箱
          </Button>
        </Col>
      </Row>


      {/* 邮箱输入框 */}
      <Modal {...emailModalConfig}>
        <Form form={emailForm}>
          <Form.Item
            label="邮箱地址"
            name="email"
            rules={[
              {
                required: true,
                message: '请输入邮箱地址',
              },
            ]}
          >
            <Input placeholder="请输入邮箱地址" />
          </Form.Item>
        </Form>
      </Modal>


    </Content>
  );
}

export default PurchaseInvoicePage;
